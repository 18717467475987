import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ExternalUpdateCustomer = React.lazy(() => import('./views/customers/UpdateCustomer'));
const ExternalCreateCustomer = React.lazy(() => import('./views/customers/CreateCustomer'));
const ExternalQuoteDetails = React.lazy(() => import('./views/quotes/ExternalQuoteDetails'));
const ExternalInvoiceDetails = React.lazy(() => import('./views/invoices/ExternalInvoiceDetails'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/external/update-customer/:id" name="Update Customer" render={props => <ExternalUpdateCustomer {...props} />} />
            <Route exact path="/external/create-customer" name="Create Customer" render={props => <ExternalCreateCustomer {...props} />} />
            <Route exact path="/q/:id" name="External Quote Details" render={props => <ExternalQuoteDetails {...props} />} />
            <Route exact path="/i/:id" name="External Invoice Details" render={props => <ExternalInvoiceDetails {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
