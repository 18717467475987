const loadConfigs = () => {
  const localConfigs = localStorage.getItem('app.configs');
  if (localConfigs) {
    return JSON.parse(localConfigs);
  }
  return {
    sidebarShow: 'responsive',
    itemsPerPage: 20,
    role: ''
  }
}

const initialState = loadConfigs();

export const appReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      localStorage.setItem('app.configs', JSON.stringify({ ...state, ...rest }));
      return { ...state, ...rest }
    default:
      return state
  }
}